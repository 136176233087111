import axios, { AxiosInstance } from 'axios';
import https from 'https';
import Cookies from 'js-cookie';

export const OK_STATUSES = [200, 201];

/**
 * Custom axios instance
 * @returns AxiosInstance
 */
export const httpClient = (): AxiosInstance => {
  const token = Cookies.get('token');

  const host = window.location.hostname;

  const instance = axios.create({
    baseURL: `https://${host}/etameapi`,
    // baseURL: `https://18.199.56.114/etameapi`,
    // baseURL: `http://localhost:8000/etameapi`,
    // baseURL: `https://etame.app/etameapi`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  });

  return instance;
};
