'use client';

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  Flex,
  Text,
  TextInput,
  Select,
  rem,
  Box,
  Switch,
  MultiSelect,
  PasswordInput,
  SegmentedControl,
  NumberInput,
} from '@mantine/core';
import { useUser } from '@/hooks/useUser';
import { CaretLeft, Person, Buildings } from '@phosphor-icons/react';
import { useRouter } from 'next/navigation';
import { useTranslation } from '@/hooks';
import { UseNonPersistentStore } from '@/hooks/store';
import classes from './AddUser.module.css';
import { useForm } from '@mantine/form';
import { PageHeader, Button } from '@/components';
import { useStore } from '@/hooks/store';
import { addMaterial } from '@/lib/addMaterial';
import { addUser } from '@/lib/addUser';
import { ROLES } from '@/constants/roles';

interface FormValues {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  phone_area_code?: string;
  phone?: string;
  confirm_password?: string;
  role?: string[];

  company_name: string;
  company_number: string;
  company_street: string;
  company_house: string;
  company_city: string;
  company_country: string;
}

const legalRoles = [
  'MATERIALU_RAZOTAJS (LEGAL PERSON)',
  'GENERALUZNEMEJS',
  'MEHANISMU_IZNOMATAJS (LEGAL PERSON)',
  'APAKSUZNEMEJS',
];

export const AddUser = () => {
  const { t } = useTranslation();
  const user = useUser((state) => state.user);
  const { currentRole, selectRole } = useStore();
  const router = useRouter();
  const { isLoading } = UseNonPersistentStore();
  const [type, setType] = useState('Fiziska persona');

  const roleOptions = (currentRole: string) => {
    switch (currentRole) {
      case 'ADMINISTRATORS':
        return [
          {
            value: 'MATERIALU_RAZOTAJS (LEGAL PERSON)',
            label: 'Materiālu ražotājs (Juridiska persona)',
          },
          {
            value: 'GENERALUZNEMEJS',
            label: 'Ģenerāluzņēmējs (Juridiska persona)',
          },
          {
            value: 'MEHANISMU_IZNOMATAJS (LEGAL PERSON)',
            label: 'Mehānismu iznomātājs (Juridiska persona)',
          },
        ];
      case 'GENERALUZNEMEJS':
        return [
          {
            value: 'APAKSUZNEMEJS',
            label: 'Apakšuzņēmējs (Juridiska persona)',
          },
          {
            value: 'PROJEKTU_VADITAJS (EMPLOYEE)',
            label: 'Projektu vadītājs (Darbinieks)',
          },
          {
            value: 'DARBU_VADITAJS (EMPLOYEE)',
            label: 'Darbu vadītājs (Darbinieks)',
          },
          {
            value: 'BRIGADIERIS (EMPLOYEE)',
            label: 'Brigadieris (Darbinieks)',
          },
          {
            value: 'TAMETAJS (EMPLOYEE)',
            label: 'Tāmētājs (Darbinieks)',
          },
        ];
      case 'APAKSUZNEMEJS':
        return [
          {
            value: 'PROJEKTU_VADITAJS (EMPLOYEE)',
            label: 'Projektu vadītājs (Darbinieks)',
          },
          {
            value: 'DARBU_VADITAJS (EMPLOYEE)',
            label: 'Darbu vadītājs (Darbinieks)',
          },
          {
            value: 'BRIGADIERIS (EMPLOYEE)',
            label: 'Brigadieris (Darbinieks)',
          },
          {
            value: 'TAMETAJS (EMPLOYEE)',
            label: 'Tāmētājs (Darbinieks)',
          },
        ];
      case 'MATERIALU_RAZOTAJS (LEGAL PERSON)':
        return [
          {
            value: 'MATERIALU_RAZOTAJS (EMPLOYEE)',
            label: 'Materiālu ražotājs (Darbinieks)',
          },
        ];
      case 'MEHANISMU_IZNOMATAJS (LEGAL PERSON)':
        return [
          {
            value: 'MEHANISMU_IZNOMATAJS (EMPLOYEE)',
            label: 'Mehānismu iznomātājs (Darbinieks)',
          },
        ];
      default:
        return [];
    }
  };

  const form = useForm<FormValues>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      phone_area_code: '+371',
      phone: '',
      role: [],
      company_name: '',
      company_number: '',
      company_street: '',
      company_house: '',
      company_city: '',
      company_country: '',
    },
    validate: {
      first_name: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      password: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      last_name: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      email: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      phone_area_code: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      phone: (value) => (!value ? 'Obligāti aizpildāms lauks' : null),
      role: (value: any) =>
        value.length === 0 ? 'Obligāti aizpildāms lauks' : null,
      confirm_password: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const handleSubmit = async (values: any) => {
    isLoading(true);

    const formattedValues = {
      ...values,
      role: values.role.join(','),
      master_client_id: user?.clientId,
    };

    const { confirm_password, ...userValues } = formattedValues;

    await addUser(userValues);

    isLoading(false);
    form.reset();
    router.push('/settings/users');
  };

  const selectUserType = (value: string) => {
    setType(value);
  };

  const handleBack = () => {
    router.push('/settings/users');
  };

  const shouldShowDetails = legalRoles.some((role) =>
    //@ts-ignore
    form.values.role.includes(role),
  );

  return (
    <Flex direction="column" gap={rem(24)}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex justify="space-between">
          <Flex>
            <Button
              onClick={handleBack}
              variant="transparent"
              c="grey"
              leftSection={<CaretLeft color="grey" />}
            >
              Atpakaļ
            </Button>
            <PageHeader title={form.values.first_name || 'Jauns lietotājs'} />
          </Flex>
          <Flex>
            <Button
              mr={rem(10)}
              c={'#1C1C1C'}
              bg={'#F1F1F1'}
              onClick={handleBack}
            >
              Atcelt
            </Button>
            <Button type="submit" bg={'#1C1C1C'}>
              Saglabāt
            </Button>
          </Flex>
        </Flex>
        <Box className={classes.formBox}>
          <Text pl={rem(20)} pt={rem(20)} fz={rem(16)} fw={600}>
            Detaļas
          </Text>
          <Box className={classes.upperGrid} pt={rem(-20)}>
            <TextInput
              label="Vārds"
              type="text"
              variant="unstyled"
              placeholder="Vārds"
              className={classes.inputField}
              {...form.getInputProps('first_name')}
            ></TextInput>
            <TextInput
              label="Uzvārds"
              type="text"
              variant="unstyled"
              placeholder="Uzvārds"
              className={classes.inputField}
              {...form.getInputProps('last_name')}
            ></TextInput>
            <TextInput
              type="email"
              label="E-pasts"
              variant="unstyled"
              placeholder="E-pasts"
              className={classes.inputField}
              {...form.getInputProps('email')}
            ></TextInput>
            <Flex justify="space-between">
              <Select
                label="Valsts kods"
                variant="unstyled"
                className={classes.inputField}
                w={rem(120)}
                data={['+371', '+372', '+370']}
                {...form.getInputProps('phone_area_code')}
              ></Select>
              <NumberInput
                type="tel"
                w="100%"
                ml={rem(20)}
                label="Telefona numurs"
                variant="unstyled"
                placeholder="Telefona numurs"
                className={classes.inputField}
                hideControls
                {...form.getInputProps('phone')}
              ></NumberInput>
            </Flex>
            <PasswordInput
              label="Parole"
              variant="unstyled"
              placeholder="Parole"
              className={classes.passwordField}
              {...form.getInputProps('password')}
            ></PasswordInput>
            <PasswordInput
              label="Parole (apstiprināt)"
              variant="unstyled"
              placeholder="Parole (apstiprināt)"
              className={classes.passwordField}
              {...form.getInputProps('confirm_password')}
            ></PasswordInput>
            <MultiSelect
              variant="unstyled"
              label="Lomas"
              placeholder="Izvēlēties"
              data={roleOptions(currentRole)}
              {...form.getInputProps('role')}
              mih={rem(88)}
              // comboboxProps={{ position: 'bottom', middlewares: { flip: false, shift: false }, offset: 20 }}
              className={classes.roleSelector}
            />
          </Box>
        </Box>
        {shouldShowDetails ? (
          <Box className={classes.formBox} mt={rem(30)}>
            <Text pl={rem(20)} pt={rem(20)} fz={rem(16)} fw={600}>
              Uzņēmuma detaļas
            </Text>
            <Box className={classes.upperGrid} pt={rem(-20)}>
              <TextInput
                label="Nosaukums"
                type="text"
                variant="unstyled"
                placeholder="Uzņēmuma nosaukums"
                className={classes.inputField}
                {...form.getInputProps('company_name')}
              ></TextInput>
              <TextInput
                label="Reģistācijas numurs"
                type="text"
                variant="unstyled"
                placeholder="Uzņēmuma reģistrācijas numurs"
                className={classes.inputField}
                {...form.getInputProps('company_number')}
              ></TextInput>
              <Select
                label="Reģistrācijas valsts"
                variant="unstyled"
                placeholder="Izvēlēties reģistrācijas valsti"
                className={classes.inputField}
                data={[
                  {
                    value: 'lv',
                    label: 'Latvija',
                  },
                  {
                    value: 'lt',
                    label: 'Lietuva',
                  },
                  {
                    value: 'ee',
                    label: 'Igaunija',
                  },
                ]}
                {...form.getInputProps('company_country')}
              />
              <TextInput
                label="Pilsēta"
                type="text"
                variant="unstyled"
                placeholder="Pilsētas nosaukums"
                className={classes.inputField}
                {...form.getInputProps('company_city')}
              />
              <TextInput
                label="Ielas nosaukums"
                variant="unstyled"
                placeholder="Ielas nosaukums"
                className={classes.inputField}
                {...form.getInputProps('company_street')}
              />
              <TextInput
                label="Ēkas numurs"
                variant="unstyled"
                placeholder="Ēkas numurs"
                className={classes.inputField}
                {...form.getInputProps('company_house')}
              />
            </Box>
          </Box>
        ) : null}
      </form>
    </Flex>
  );
};
