import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';
import { Client } from '@/interfaces';

export const addUser = async (user: Partial<Client>) => {
  try {
    const resp = await httpClient().post('/clients/create', user);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User created');
    }
    return resp.data;
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create user');
  }
};
