import { notifications } from '@mantine/notifications';
import { Check, Warning } from '@phosphor-icons/react/dist/ssr';

export const errorNotification = (title: string, message?: string) =>
  notifications.show({
    title,
    message,
    autoClose: 10_000,
    icon: <Warning />,
    color: 'red',
    withCloseButton: true,
  });

export const successNotification = (title: string, message?: string) =>
  notifications.show({
    title,
    message,
    autoClose: 5_000,
    icon: <Check />,
    color: 'green',
    withCloseButton: true,
  });
